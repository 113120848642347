import React from 'react'
import Link from '../Link'
import {
	resourceRowPodcast,
	playBtnWrapper,
	podcastDetails,
	podcastMeta,
	cta,
} from './ResourceRowPodcast.module.scss'
import Play from '../Play'
import StyledLabel from '../Common/StyledLabel'
import ServiceLabel from "../ServiceLabel";
import BtnLink from "../Common/StyledButton/BtnLink";

const ResourceRowPodcast = ({ ...props }) => {
	return (
		<>
			<article className={[resourceRowPodcast, 'd-flex'].join(' ')}>
				<div className={playBtnWrapper}>
					<Link to={props.href}>
						<Play align="none" bgColor="light" size="medium" />
					</Link>
				</div>
				<div className={[podcastDetails, 'flex-grow-1'].join(' ')}>
					<div
						className={[podcastMeta, 'd-flex justify-space-between'].join(' ')}
					>
						<div className="article-meta"><ServiceLabel service={props.service} separator={false} /></div>

						{props.time && (
							<div className="d-md-none ms-auto">
								{props.time && (
									<StyledLabel variant="time" size="small" time={props.time} iconColor="light"/>
								)}
							</div>
						)}
					</div>

					<h2 className="h4">
						<Link to={props.href} state={{path: props?.backLink}}>{props.title}</Link>
					</h2>

					<div className="d-none d-md-block">
						{props.time && (
							<StyledLabel variant="time" size="small" time={props.time} iconColor="light" />
						)}
					</div>
				</div>
				{/* .podcastDetails */}
				<div className={cta}>
					<BtnLink href={props.href} cta="More Info" state={{path: props?.backLink}} />
				</div>
			</article>
		</>
	)
}

export default ResourceRowPodcast
